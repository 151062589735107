<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <supplier :id="id"></supplier>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
import BreadCrumb from "../../../components/app/common/BreadCrumb";
import Supplier from "../../../components/app/supplier/supplier";

export default {
    name: 'Supplier_view',
    data: function(){
        return {
            pageName: 'Supplier',
            segments: [
                { link: true, text: 'Suppliers', routeName: 'App.Suppliers' },
                { link: false, text: 'Supplier' },
            ],
            id: 0
        }
    },
    props: {},
    components: {
        Supplier,
        BreadCrumb
    },
    computed: {

    },
    methods:{

    },
    created() {
        this.id = this.$route.params.id;
    }
}
</script>

<style scoped>

</style>
